import React from 'react';
import PrismaTable from '../components/PrismaTable';
import ScopeAdminStyle from '../components/ScopeAdminStyle';

const GeneralHealthQuestion: React.FC = () => {
  return (
    <ScopeAdminStyle>
      <PrismaTable model="GeneralHealthQuestion"/>
    </ScopeAdminStyle>
  );
};

export default GeneralHealthQuestion;
