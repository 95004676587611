import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Flex, Form, message, Modal, Popconfirm, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import { FormAction } from '../Form/enums/FormActions';
import { IFormInstance, IFormProps } from '../Form/interfaces/IForm';
import { DeleteOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface FormModalProps {
  open: boolean;
  reset: () => void;
  title?: string;
  action?: FormAction;
  onDelete?: (ids: string[]) => void;
  onSubmit?: (v: any) => void;
  record?: any;
  FormComponent?: React.FC<IFormProps | any>;
  width?: string;
}

// reset form fields when modal is form, closed
const useSetFormOnModalStateChange = ({ form, open, record }: { form: FormInstance; open: boolean, record: any }) => {
  const prevOpenRef = useRef<boolean>();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    } else if (open && !prevOpen) {
      form.setFieldsValue(record || {});
    }
  }, [form, prevOpen, open, record]);
};

const FormModal: React.FC<FormModalProps> = (props) => {
  const {
    open,
    action = FormAction.View,
    record,
    reset,
    onSubmit,
    onDelete,
    title,
    FormComponent,
  } = props;

  const [form] = Form.useForm();

  const [instance, setInstance] = useState<IFormInstance>({});

  useSetFormOnModalStateChange({
    form,
    open,
    record,
  });

  const titleNode = useMemo(
    () => (
      <Title level={3} style={{ marginTop: 0 }}>
        {title || ''}
      </Title>
    ),
    [title],
  );

  const onOk = async () => {
    form.submit();
    try {
      await form.validateFields();
      form.submit();
    } catch (e) {
      message.error('Please, fill all the required data');
      throw e;
    }
  };

  if (!FormComponent) {
    return null;
  }

  return (
    <Modal title={titleNode} open={open} onCancel={reset} footer={null} width={props.width}>
      <FormComponent
        form={form}
        action={action}
        record={record}
        onSubmit={onSubmit}
        afterSubmit={reset}
        setInstance={setInstance}
      />
      <Flex justify="space-between">
        {
          action !== FormAction.Create && onDelete
            ? (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => onDelete([record.id])}
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  danger: true,
                }}
              >
                <Button danger type="text" icon={<DeleteOutlined/>}>
                  Delete
                </Button>
              </Popconfirm>
            )
            : <div/>
        }
        <Flex wrap="wrap" gap="small">
          <Button onClick={reset}>
            Cancel
          </Button>
          <Button type="primary" onClick={onOk} disabled={instance.isLoading}>
            Save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default FormModal;