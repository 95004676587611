export const lowercaseFirstLetter = (str: string | undefined) => {
  if (typeof str === 'undefined') {
    return '';
  }
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const uppercaseFirstLetter = (str: string | undefined) => {
  if (typeof str === 'undefined') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const millisecondsToDays = (milliseconds: number) => {
  return milliseconds / 1000 / 60 / 60 / 24;
};

export const daysToMilliseconds = (days: number) => {
  return days * 1000 * 60 * 60 * 24;
};

export function getPortalSettingsItem(id) {
  return window.__injectedPortalSettings__?.[id];
}