import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Space, Tooltip, message } from 'antd';
import { useParams } from 'react-router-dom'
import {
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Context } from '../Context';
import { FormAction } from '../Form/enums/FormActions';
import { useLazyQuery, useMutation } from '@apollo/client';
import { mutationDocument, queryDocument as getDocumentQuery } from '../QueryDocument';
import { lowercaseFirstLetter } from '../../../utils';
import pluralize from 'pluralize';
import { getActionType } from '../Form/utils';
import { ITableWrapper } from '../types';
import Table from './index';

const TableWrapper: React.FC<ITableWrapper> = (props) => {
  const {
    query: variables = {},
    secondaryHeader,
    onUpdateClick,
    onCreateClick,
    enableDelete = true,
    onDelete,
    columns,
    modelId,
    headerActions: headerActionsProp,
  } = props;

  const context = useContext(Context);
  const {
    schema,
    pagesPath,
    eventEmitter,
  } = context;
  const { models } = schema;

  const [dataRes, setDataRes] = useState<any>();

  const params = useParams<{ action?: string, parts?: string[] }>();

  const isInEditOrViewState = useMemo(
    () => {
      const action = getActionType(params.action);
      return action === FormAction.Update || action === FormAction.View;
    },
    [params.action],
  );

  const recordId = params.parts?.[1];
  const {
    modelName,
  } = useMemo(
    () => {
      const _modelObject = models.find((item) => item.id === modelId);
      return {
        modelName: _modelObject?.name,
        modelFields: _modelObject?.fields,
      };
    },
    [models, modelId],
  );

  const modelQuery = useMemo(
    () => pluralize(lowercaseFirstLetter(modelId)),
    [modelId],
  );

  const query = useMemo(() => 
    getDocumentQuery(schema, modelId, false, !!columns),
  [schema, modelId, columns]);

  const [getData, { data: dataFromQuery, loading, error }] = useLazyQuery(
    query,
    {
      variables,
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(
    () => {
      setDataRes(dataFromQuery);
    },
    [dataFromQuery],
  );

  const refreshData = useCallback(async () => {
    const _res = await getData();
    setDataRes(_res.data);
  }, [getData, setDataRes]);

  useEffect(
    () => {
      eventEmitter
        .on(`get-data-table-${modelId}`, () => {
          refreshData();
        });
    },
    [eventEmitter, modelId, refreshData],
  );

  const [deleteMany] = useMutation(
    mutationDocument(schema, modelId, 'delete')
  );

  const value: [] = useMemo(
    () => {
      return dataRes
        ? dataRes[modelQuery]
          ?.map((it: any) => {
            return {
              ...it,
              key: it.id,
            };
          })
        : [];
    },
    [dataRes, modelQuery],
  );

  useEffect(() => {
    // TODO: do we need this
    let timeOut: NodeJS.Timeout;
    if ((!recordId || isInEditOrViewState) && !dataRes && !loading && !error) {
      timeOut = setTimeout(() => {
        getData();
      }, 5);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [dataRes, loading, params, error, getData, isInEditOrViewState, recordId]);

  const handleDeleteSelected = async (selectedRowKeys: React.Key[]) => {
    try {
      await onDelete?.(selectedRowKeys); // TODO: duplicated action? (DefaultModalPage)
      await deleteMany({
        variables: {
          where: {
            id: {
              in: selectedRowKeys,
            },
          },
        },
      });
      refreshData();
    } catch (e) {
      console.error(e);
      message.error('Error trying delete record');
    }
  };

  const handleAddNewClick = () => {
    onCreateClick
      ? onCreateClick()
      : router.push(`${pagesPath}${modelId}?action=create`);
  };

  const handleUpdateClick = (record: any) => () => {
    onUpdateClick
      ? onUpdateClick(record)
      : router.push(`${pagesPath}${modelId}/${record.id}?action=${FormAction.Update}`);
  };

  
  // const handleViewClick = (record: any) => () => {
  //   onViewClick
  //     ? onViewClick(record)
  //     : router.push(`${pagesPath}${modelId}/${record.id}?action=${FormAction.View}`);
  // };

  const customColumns = [
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'left',
      width: '90px',
      render: (_, record: any) =>
        <Space>
          <Tooltip title="Edit">
            <Button
              type="link"
              icon={<EditOutlined/>}
              onClick={handleUpdateClick(record)}
            />
          </Tooltip>
          {/* <Tooltip title="View">
            <Button
              type="link"
              icon={<EyeOutlined/>}
              onClick={handleViewClick(record)}
            />
          </Tooltip> */}
        </Space>
      ,
    },
  ];

  const isEmbedded = !!modelId;

  const headerActions = headerActionsProp || (
    <Tooltip title={`Add ${modelName || ''}`}>
      <Button
        type="primary"
        icon={<PlusOutlined/>}
        onClick={handleAddNewClick}
      >
        Add {isEmbedded ? modelName : ''}
      </Button>
    </Tooltip>
  );

  return (
    <Table
      data={value}
      isEmbedded={isEmbedded}
      secondaryHeader={secondaryHeader}
      customColumns={customColumns}
      modelId={modelId}
      onCreateClick={handleAddNewClick}
      onRemoveClick={enableDelete ? handleDeleteSelected : undefined}
      loading={loading}
      columns={columns}
      headerActions={headerActions}
    />
  );
};

export default TableWrapper;
