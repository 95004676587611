import React from 'react';
import PrismaTable from '../components/PrismaTable';
import ScopeAdminStyle from '../components/ScopeAdminStyle';

const User: React.FC = () => {
  return (
    <ScopeAdminStyle>
      <PrismaTable model="User"/>
    </ScopeAdminStyle>
  )
};

export default User;
