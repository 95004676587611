import React, { useContext, useMemo } from 'react';
import { useRouteMatch, Route, useHistory, useLocation } from 'react-router-dom';
import VaccinationType from './pages/VaccinationType';
import Vaccination from './pages/Vaccination';
import User from './pages/User';
import MeningitisWaiver from './pages/MeningitisWaiver';
import GeneralHealthQuestion from './pages/GeneralHealthQuestion';
import GeneralHealthAnswerOption from './pages/GeneralHealthAnswerOption';
import GeneralHealthAnswer from './pages/GeneralHealthAnswer';
import LanguageItem from './pages/LanguageItem';
import { useApolloClient, useQuery } from '@apollo/client';
import { AdminSchema } from '../schema/types/adminSchema';
import { Context, defaultSettings } from './components/Context';
import { GET_SCHEMA } from './components/SchemaQueries';
import Attachment from './pages/Attachment';
import { LIST_ITEMS } from './pages/config';
import Layout, { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { Menu } from 'antd';

const Admin = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const { eventEmitter, schema } = useContext(Context);

  const apolloClient = useApolloClient();

  const { data } = useQuery<{ getSchema: AdminSchema }>(GET_SCHEMA, { client: apolloClient });

  const menuItems = useMemo(() => 
    LIST_ITEMS.map((item, i) => ({ key: item.path, label: item.title }))
  ,[]);

  return (
    <>
      <Context.Provider
        value={{
          schema: data?.getSchema ?? schema,
          eventEmitter,
          ...defaultSettings,
        }}
      >
      <Layout>
          <Sider width={220}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              style={{
                height: '100%',
              }}
              items={menuItems}
              onClick={({ key }) => history.push(key)}

            />
          </Sider>
          <Content
            style={{
              padding: '24px 24px',
              minHeight: 280,
            }}
          >
            <Route path={`${path}/User`} component={User} />
            <Route path={`${path}/VaccinationType`} component={VaccinationType} />
            <Route path={`${path}/Vaccination`} component={Vaccination} />
            <Route path={`${path}/MeningitisWaiver`} component={MeningitisWaiver} />
            <Route
              path={`${path}/GeneralHealthQuestion`}
              component={GeneralHealthQuestion}
            />
            <Route
              path={`${path}/GeneralHealthAnswerOption`}
              component={GeneralHealthAnswerOption}
            />
            <Route
              path={`${path}/GeneralHealthAnswer`}
              component={GeneralHealthAnswer}
            />
            <Route path={`${path}/Language`} component={LanguageItem} />
            <Route path={`${path}/Attachment`} component={Attachment} />
          </Content>
        </Layout>

      </Context.Provider>
    </>
  );
};

export default Admin;
