import { useMemo } from 'react';
import {
  Route,
  HashRouter,
  Redirect,
  Switch,
} from 'react-router-dom';
import { useStore } from './store';
import shallow from 'zustand/shallow';
import theme from './miu-theme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Home from './pages/home';
import Profile from './pages/profile';
import Vaccinations from './pages/vaccinations';
import SignIn from './pages/sign-in';
import moment from 'moment-timezone';
import MeningitisWaiverDialog from './components/meningitis-waiver-dialog';
import Admin from './admin';
import MainLayout from './layouts/main';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

moment.tz.setDefault('Etc/UTC');

export default function App() {
  const [
    userToken,
    parseToken,
  ] = useStore(
    (store) => [
      store.userToken,
      store.parseToken,
    ],
    shallow,
  );

  const isAdmin = useMemo(
    () => {
      if (!userToken) {
        return;
      }
      const res = parseToken(userToken);
      return !!res.isAdmin;
    },
    [userToken, parseToken],
  );

  const apolloClient = useMemo(() => {
    return new ApolloClient({
      uri: '/api/graphql',
      cache: new InMemoryCache(),
    });
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={theme}>
      <Switch>
        <Route>
          <MainLayout>
            <Route path="/vaccinations">
              {userToken ? <Vaccinations/> : <Redirect to="/sign-in"/>}
            </Route>
            <Route path="/profile">
              {userToken ? <Profile/> : <Redirect to="/sign-in"/>}
            </Route>
            <Route path="/sign-in" component={SignIn}/>
            <Route exact path="/" component={Home}/>
            <Route path="/admin">
              {
                (userToken)
                  ? isAdmin
                    ? (
                      <Admin/>
                    )
                    : <Redirect to="/"/>
                  : <Redirect to="/sign-in"/>
              }
            </Route>
          </MainLayout>
        </Route>
        <HashRouter hashType="noslash">
          <Route path="/meningitis-info-dialog" component={MeningitisWaiverDialog}/>
        </HashRouter>
      </Switch>
    </ThemeProvider>
    </ApolloProvider>
  );
}
