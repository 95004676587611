import React from 'react';
import { IFormProps } from '../Form/interfaces/IForm';
import FormWrapper from '../Form/FormWrapper';
import TextField from '../Form/fields/TextField';
import RichTextField from '../Form/fields/RichTextField';

const modelId = 'LanguageItem';

const LanguageItemForm: React.FC<IFormProps> = (props) => {
  return (
    <FormWrapper {...props} showButtons={false} modelId={modelId}>
      <TextField
        name="id"
        label="ID"
        required
      />

      <TextField
        name="description"
        label="Description"
        required
      />

      <RichTextField
        name="content"
        label="Content"
        required
      />
    </FormWrapper>
  );
}

export default LanguageItemForm;
