import React from 'react';
import PrismaTable from '../components/PrismaTable';
import ScopeAdminStyle from '../components/ScopeAdminStyle';

const VaccinationType: React.FC = () => {
  return (
    <ScopeAdminStyle>
      <PrismaTable model="VaccinationType"/>;
    </ScopeAdminStyle>
  );
};

export default VaccinationType;
