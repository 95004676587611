import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, message, Space, Spin } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { queryDocument } from '../QueryDocument';
import { Context } from '../Context';
import { IFormProps } from './interfaces/IForm';
import { FormAction } from './enums/FormActions';
import useActions from '../hooks/useActions';
import { lowercaseFirstLetter } from '../../../utils';
import { FormContext } from './FormContext';
import StaticField from './fields/StaticField';
import TextField from './fields/TextField';
import { useHistory } from 'react-router-dom';

interface IFormWrapperProps extends IFormProps {
  children: React.ReactNode;
  modelId: string;
  showButtons?: boolean;
  showId?: boolean;
}

const FormWrapper: React.FC<IFormWrapperProps> = (props) => {
  const {
    action = FormAction.View,
    recordId,
    modelId,
    children,
    showButtons = true,
    afterSubmit,
    layout = 'vertical',
    showId = false,
    getInitialValues, // TODO:1  it resets when reopen modal
    setInstance,
  } = props;

  const {
    schema,
    onCancelUpdate,
    eventEmitter,
  } = useContext(Context);

  const loadingCount = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState();

  useEffect(
    () => {
      setInstance?.({ isLoading });
    },
    [isLoading, setInstance],
  );

  const increaseLoadingCount = useCallback(
    () => {
      loadingCount.current++;
      setIsLoading(loadingCount.current > 0);
    },
    [],
  );

  const decreaseLoadingCount = useCallback(
    () => {
      loadingCount.current--;
      setIsLoading(loadingCount.current > 0);
    },
    [],
  );

  const [formState] = Form.useForm();
  const form = props.form || formState;

  const { goBack } = useHistory()  

  const { models } = schema;
  const modelObject = models.find((item) => item.id === modelId);

  const [getRecord, queryResult] = useLazyQuery(
    queryDocument(schema, modelId, true, true), {
      variables: modelObject
        ? {
          where: {
            [modelObject.idField]: recordId,
          },
        }
        : undefined,
      fetchPolicy: 'network-only',
    });

  const { data, loading, error, called } = queryResult;

  const modelQuery = useMemo(
    () => lowercaseFirstLetter(modelId),
    [modelId],
  );
  const record = props.record || data?.[modelQuery];

  const { onSubmit } = useActions(modelObject!, initialFormValues, action, () => {
    eventEmitter.emit(`get-data-table-${modelId}`);
  });

  useEffect(() => {
    if (!props.record && modelObject && !data && !loading && !error) {
      getRecord();
    }
  }, [modelObject, data, loading, error, props.record, getRecord]);

  const isNonExistentRecord = called && !record && action !== FormAction.Create && !loading;

  useEffect(() => {
    if (isNonExistentRecord) {
      return;
    }
    const _initialValues = (
      action === FormAction.Create
        ? getInitialValues?.()
        : record
    ) || {};
    form.setFieldsValue(_initialValues);
    const formValues = form.getFieldsValue();
    setInitialFormValues(formValues);
  }, [record, getInitialValues, action, form, isNonExistentRecord]);

  const onUpdateCancel = onCancelUpdate || goBack;

  const onFinish = async (values: any) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      if (props.onSubmit) {
        await props.onSubmit(values);
      } else {
        await onSubmit(values);
        message.success(`${modelObject?.name} saved`);
      }
      await afterSubmit?.();
    } catch (e) {
      console.error(e);
      message.error(`Error saving ${modelObject?.name}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isNonExistentRecord) {
    return <div>Record does not exist</div>;
  }

  // TODO: extract to the upper level
  if (action === FormAction.Unknown) {
    return <div>Seems like you have entered an invalid URL</div>;
  }

  return (
    <Form
      name={`${modelId}Form`}
      style={{ display: 'flex', flexDirection: 'column' }}
      wrapperCol={{ span: 24 }}
      layout={layout}
      labelWrap
      form={form}
      onFinish={onFinish}
    >
      <Spin spinning={!!(record && loading)}>
        {
          (record || action === FormAction.Create)
            ? <FormContext.Provider
              value={{
                action,
                modelObject,
                record,
                form,
                increaseLoadingCount,
                decreaseLoadingCount,
              }}
            >
              {
                showId
                  ? <StaticField name="id" label="ID"/>
                  : <TextField name="id" hidden/>
              }
              <TextField name="__typename" hidden/>
              {children}
              {
                showButtons &&
                <Space wrap>
                  {
                    action !== FormAction.View &&
                    <Button type="primary" htmlType="submit">
                      Save {modelObject?.name}
                    </Button>
                  }
                  <Button onClick={() => onUpdateCancel({ model: modelId })}>
                    {
                      action === FormAction.View
                        ? 'Back'
                        : 'Cancel'
                    }
                  </Button>
                </Space>
              }
            </FormContext.Provider>
            : null
        }
      </Spin>
    </Form>
  );
};

export default FormWrapper;