import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import style from "!!raw-loader!@paljs/admin/style.css";

const ScopeAdminStyle = ({ children }) => {
  return (
    <div>
      <style scoped dangerouslySetInnerHTML={{ __html: style }}></style>
      {children}
    </div>
  )
}

export default ScopeAdminStyle;