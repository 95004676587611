import React from 'react';
import { Form, Tag, Typography } from 'antd';
import IFieldProps from '../fields/IFieldProps';
import { millisecondsToDays } from '../../../../utils';
import dayjs from 'dayjs';
import { dateFormat } from '../../../../constants';
import { FormFieldTypes } from '../enums/FormTypes';
import { CheckOutlined } from '@ant-design/icons';

interface IStaticText {
  value: any;
  type?: FormFieldTypes;
  displayField?: string;
}

const getObjectValue = ({ type, value, displayField }: IStaticText) => {
  const _getObjectValue = (v: any) => {
    return v[displayField!]
      ?? v.label // just edited from DebounceSelectField
      // TODO:1  displayField used in label are only adjustable from inside of DebounceSelectField. think how to fix it
      ?? v.id;
  };

  switch (type) {
    case FormFieldTypes.multichoice: {
      return value.map((curr: any) => {
        return _getObjectValue(curr);
      });
    }
    default: {
      return _getObjectValue(value);
    }
  }
};

const formatValue = ({ type, value }: IStaticText) => {
  if (Array.isArray(value)) {
    return value.map((curr: any, i: number) => {
      return (
        <Tag key={i}>
          {curr}
        </Tag>
      );
    });
  }
  switch (type) {
    case FormFieldTypes.duration: {
      return `${millisecondsToDays(value)} days`;
    }
    case FormFieldTypes.date: {
      return dayjs(value).format(dateFormat);
    }
    case FormFieldTypes.checkbox: {
      return value ? <CheckOutlined/> : '';
    }
    default: {
      // TODO:1  this is temporary v
      if (typeof value === 'boolean') {
        return value ? <CheckOutlined/> : '';
      }
      return value;
    }
  }
};

const StaticText: React.FC<IStaticText> = ({ value, type, displayField }) => {
  const _value = displayField
    ? (value && getObjectValue({ type, value, displayField })) // TODO:1  need a type of the field
    : value;
  const formattedValue = typeof _value !== 'undefined' && _value !== null
    && formatValue({ type, value: _value });
  return (
    <Typography.Text style={{ float: 'left' }}>
      {formattedValue}
    </Typography.Text>
  );
};

const StaticField: React.FC<IFieldProps & { displayField?: string }> = (props) => {
  const { label, name, tooltip, style = undefined, type, displayField } = props;
  return (
    <Form.Item
      label={label}
      name={name}
      tooltip={tooltip}
      style={style}
    >
      <StaticText type={type} displayField={displayField}/>
    </Form.Item>
  );
};

export default StaticField;