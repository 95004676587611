/* eslint  @typescript-eslint/unbound-method: 0 */
import React from 'react';
import defaultLanguage from './language';
import { ContextProps } from './types';
import { EventEmitter } from 'events';

export const defaultSettings = {
  pagesPath: '/',
  pageSize: 10,
  pageSizeOptions: [10, 20, 30, 40, 50, 100],
  paginationOptions: 4,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  push: typeof window !== 'undefined' ? window.location.replace : () => {
  },
  query: {},
  lang: defaultLanguage,
};

const initialContext: ContextProps = {
  schema: {
    models: [],
    enums: [],
  },
  eventEmitter: new EventEmitter(),
  ...defaultSettings,
};

export const Context = React.createContext<ContextProps>(initialContext);
