/* eslint @typescript-eslint/no-non-null-assertion: 0 */
import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { Context } from '../Context';
import { FormAction } from '../Form/enums/FormActions';
import { mutationDocument } from '../QueryDocument';
import { AdminSchemaField, AdminSchemaModel } from '../types';
import { KindEnum } from '../../../schema/enums/Kind';

interface GetValueOptions {
  value: string;
  field?: AdminSchemaField;
  useSet?: boolean;
}

type IndefiniteObject = { [key: string]: any };

export function getValueByType({ value, field, useSet = true }: GetValueOptions) {
  if (!field) {
    return value;
  }
  if (field.type === 'Json') {
    return value ? JSON.parse(value) : field.list ? [] : {};
  }
  if (field.list) {
    if (!value) {
      return [];
    }
    const result: any[] = Array.isArray(value) ? [...value] : value?.split(',');
    switch (field.type) {
      case 'Int':
        result.forEach((value1, index) => {
          result[index] = parseInt(value1);
        });
        break;
      case 'Float':
        result.forEach((value1, index) => {
          result[index] = parseFloat(value1);
        });
        break;
      case 'Boolean':
        result.forEach((value1, index) => {
          result[index] = value1 === 'true';
        });
        break;
    }
    return result;
  }
  const result = ['BigInt', 'Int'].includes(field.type)
    ? parseInt(value)
    : ['Float', 'Decimal'].includes(field.type)
      ? parseFloat(value)
      : value;
  return !useSet ? result : { set: result };
}

// function getFields(modelId: string): AdminSchemaField[] {
//   const { schema: { models } } = useContext(Context);
//   return models.find((item: { id: string; }) => item.id === modelId)!.fields;
// }

function filterValueObjectFields(
  { value, fields }
    : { value: IndefiniteObject, fields: AdminSchemaField[] }
): IndefiniteObject | undefined {
  const res: any = {};
  Object.entries(value).forEach(([key, value]) => {
    const isSchemaField = fields.some(({ name }: { name: any }) => name === key);
    if (isSchemaField) {
      res[key] = value;
    }
  });
  return Object.keys(res).length ? res : undefined;
}

function getUnequalProps(
  { newValue, oldValue, shouldBeUpdated, fields, deleteOnUnlink = false }
    : {
    newValue: IndefiniteObject,
    oldValue: IndefiniteObject,
    shouldBeUpdated: boolean,
    deleteOnUnlink?: boolean,
    fields: AdminSchemaField[],
  }
): IndefiniteObject | undefined {
  const res: IndefiniteObject = {};
  const fieldsObj: IndefiniteObject = {};
  fields.forEach((field) => {
    fieldsObj[field.name] = field;
  });
  Object.entries(newValue)
    .forEach(([key, value]) => {
      const field = fieldsObj[key];
      if (field.kind === KindEnum.OBJECT) {
        const childFields: AdminSchemaField[] = [{ name: 'id' }]; // TODO
        const isArray = Array.isArray(value);
        // only connect and disconnect, no create and update for both objects and arrays
        if (isArray) {
          const arrayData = getArrayData({
            newValue: value,
            oldValue: oldValue[key],
            fields: childFields,
            shouldBeUpdated,
            deleteOnUnlink,
          });
          if (arrayData) {
            res[key] = arrayData;
          }
        } else {
          const resProp = getObjectResPropData({
            newValue: value,
            oldValue: oldValue[key],
            fields: childFields,
            isFromArray: false,
            shouldBeUpdated,
            deleteOnUnlink,
          });
          if (resProp) {
            res[key] = {
              [resProp.method]: resProp.value,
            }
          }
        }
      } else if (oldValue[key] !== value) {
        res[key] = {
          set: value,
        };
      }
    });
  return Object.keys(res).length ? res : undefined;
}

function getArrayData(
  { newValue, oldValue, fields, shouldBeUpdated = true, deleteOnUnlink = false }
    : {
    newValue: IndefiniteObject[],
    oldValue: IndefiniteObject[],
    fields: AdminSchemaField[],
    shouldBeUpdated?: boolean,
    deleteOnUnlink?: boolean,
  }
): IndefiniteObject | undefined {
  const res: IndefiniteObject = {};
  const addArrayItemToRes = (method: string, value: IndefiniteObject) => {
    if (method === 'update' && res.update) {
      // if there are more than one prop to update for an item with the same id, merge them into one "update" item
      const foundIndex = res.update.findIndex(({ where }: { where: IndefiniteObject }) => where.id === value.where.id);
      if (foundIndex !== -1) {
        const newUpdateValue = [...res.update];
        newUpdateValue[foundIndex].data = {
          ...newUpdateValue[foundIndex].data,
          ...value.data,
        };
        res.update = newUpdateValue;
        return;
      }
    }
    res[method] = [
      ...(res[method] || []),
      value,
    ];
  };
  const newValueObj: IndefiniteObject = {};
  // fill newValueObj for faster search
  newValue.forEach((value) => {
    newValueObj[value.id] = value;
  });
  const oldValueObj: IndefiniteObject = {};
  oldValue.forEach((value) => {
    // fill oldValueObj
    oldValueObj[value.id] = value;
    // check if there are any deleted items
    const resProp = getObjectResPropData({
      newValue: newValueObj[value.id],
      oldValue: value,
      fields,
      isFromArray: true,
      shouldBeUpdated,
      deleteOnUnlink,
    });
    if (resProp) {
      addArrayItemToRes(resProp.method, resProp.value);
    }
  });
  newValue.forEach((value) => {
    // check which values to create, connect or update
    const resProp = getObjectResPropData({
      newValue: value,
      oldValue: oldValueObj[value.id],
      fields,
      isFromArray: true,
      shouldBeUpdated,
      deleteOnUnlink,
    });
    if (resProp) {
      addArrayItemToRes(resProp.method, resProp.value);
    }
  });
  return Object.keys(res).length ? res : undefined;
}

function getCreateData({ fields, value }: { fields: AdminSchemaField[], value: IndefiniteObject }) {
  const fieldsObj: IndefiniteObject = {};
  fields.forEach((field: AdminSchemaField) => {
    fieldsObj[field.name] = field;
  });
  const dataToCreate: IndefiniteObject = {};
  Object.entries(value)
    .forEach(([key, v]) => {
      // TODO: not sure if this is the right thing to do, didn't think much here
      if (!v) {
        return;
      }
      const field = fieldsObj[key];
      if (field.kind === KindEnum.OBJECT) {
        dataToCreate[key] = {
          connect: Array.isArray(v)
            ? v.map((curr) => {
              return { id: curr.id };
              // const subFields = getFields(field.type);
              // return filterValueObjectFields({ value: curr, fields: subFields });
            })
            : { id: v.id },
        };
      } else {
        dataToCreate[key] = v;
      }
    });
  return dataToCreate;
}

function getObjectResPropData(
  { newValue, oldValue, fields, isFromArray = false, shouldBeUpdated = true, deleteOnUnlink = false }
    : {
    newValue: IndefiniteObject | undefined,
    oldValue: IndefiniteObject | undefined,
    fields: AdminSchemaField[],
    isFromArray?: boolean,
    shouldBeUpdated?: boolean,
    deleteOnUnlink?: boolean,
  }
): { method: string, value: any } | undefined {
  const newValueWithOnlySchemaFields: IndefiniteObject | undefined =
    newValue && filterValueObjectFields({ value: newValue, fields });
  if (!newValueWithOnlySchemaFields) {
    return oldValue
      ? {
        method: deleteOnUnlink ? 'delete' : 'disconnect',
        value: isFromArray ? { id: oldValue.id } : true,
      }
      : undefined;
  }
  if (!newValueWithOnlySchemaFields.id) {
    const dataToCreate = getCreateData({
      fields,
      value: newValueWithOnlySchemaFields,
    });
    return dataToCreate
      ? {
        method: 'create',
        value: dataToCreate,
      }
      : undefined;
  }
  if (!oldValue || oldValue.id !== newValueWithOnlySchemaFields.id) {
    return {
      method: 'connect',
      value: { id: newValueWithOnlySchemaFields.id },
    };
  }
  if (!shouldBeUpdated) {
    return;
  }
  const dataToUpdate = getUnequalProps({
    newValue: newValueWithOnlySchemaFields,
    oldValue,
    shouldBeUpdated: false,
    fields,
  });
  return dataToUpdate
    ? {
      method: 'update',
      value: {
        where: { id: newValueWithOnlySchemaFields.id },
        data: dataToUpdate,
      },
    }
    : undefined;
}

function getObjectData(
  { oldData, newData, key, models, field }
    : { newData: any, oldData?: any, key: string, models: AdminSchemaModel[], field: AdminSchemaField }
): IndefiniteObject | undefined {
  const fieldModel: AdminSchemaModel = models.find((item: { id: string; }) => item.id === field.type)!;
  const fields = fieldModel.fields;
  const fieldEdit: AdminSchemaField = fields.find((item: { name: any; }) => item.name === fieldModel.idField)!;

  let newValue = getValueByType({
    value: newData[key],
    field: fieldEdit,
    useSet: false,
  });
  if (!newValue) {
    return oldData ? { unset: true } : undefined;
  }
  if (typeof newValue !== 'object') {
    if (typeof newValue === 'string') {
      newValue = { id: newValue };
    } else {
      throw new Error(`Value should be an object: ${newValue}`);
    }
  }
  const isArray = Array.isArray(newValue);
  const oldValue = getValueByType({
    value: oldData?.[key],
    field: fieldEdit,
    useSet: false,
  }) || (isArray ? [] : {});
  const deleteOnUnlink = field.deleteOnUnlink || false;
  if (isArray) {
    return getArrayData({
      newValue,
      oldValue,
      fields,
      deleteOnUnlink,
    });
  }
  const resProp = getObjectResPropData({
    newValue,
    oldValue,
    fields,
    deleteOnUnlink,
  });
  return resProp
    ? { [resProp.method]: resProp.value }
    : undefined;
}

function useActions(model: AdminSchemaModel, data: any, action: FormAction, onSave?: (data?: any) => void) {
  if (!model) {
    throw new Error('Model is required')
  }

  const {
    schema,
    valueHandler,
    useSet,
  } = useContext(Context);
  const { models } = schema;
  const [updateModel, { loading: updateLoading }] = useMutation(mutationDocument(schema, model?.id, 'update'));
  const [createModel, { loading: createLoading }] = useMutation(mutationDocument(schema, model?.id, 'create'));
  const getField = (name: string) => {
    return model.fields.find((item) => item.name === name);
  };

  const onUpdateHandler = async (newData: any) => {
    const filteredData = filterValueObjectFields({
      value: newData,
      fields: model.fields,
    });
    if (!filteredData) {
      return;
    }
    const updateData: any = {};
    Object.keys(filteredData)
      .forEach((key) => {
        const field = getField(key);
        if (!field?.update || newData[key] === data[key]) {
          return;
        }
        if (field.kind === KindEnum.OBJECT) {
          const objectData = getObjectData({
            newData,
            oldData: data,
            field,
            key,
            models,
          });
          if (objectData) {
            updateData[key] = objectData;
          }
        } else {
          updateData[key] = valueHandler
            ? valueHandler(newData[key], field)
            : getValueByType({ value: newData[key], field, useSet });
        }
      });
    if (Object.keys(updateData).length <= 0) {
      return;
    }
    await updateModel({
      variables: {
        where: {
          [model.idField]: data[model.idField],
        },
        data: updateData,
      },
    });
    onSave?.();
  };

  const onCreateHandler = async (newData: any) => {
    const createData: any = {};
    Object.keys(newData)
      .forEach((key) => {
        const field = getField(key);
        if (field?.kind === KindEnum.OBJECT) {
          // TODO: initial values
          const objectData = getObjectData({
            newData,
            field,
            key,
            models,
          });
          if (objectData) {
            createData[key] = objectData;
          }
        } else {
          createData[key] = valueHandler
            ? valueHandler(newData[key], field, true)
            : getValueByType({
              value: newData[key],
              field,
              useSet: false,
            });
        }
      });
    if (Object.keys(createData).length <= 0) {
      return;
    }
    const result = await createModel({
      variables: {
        data: createData,
      },
    });
    onSave?.(result.data[`createOne${model.id}`]);
  };

  const onSubmit = async (newData: any) => {
    return action === FormAction.Create
      ? onCreateHandler(newData)
      : onUpdateHandler(newData);
  };

  return {
    onSubmit,
    loading: updateLoading || createLoading,
  };
}

export default useActions;
