import { FormAction } from './enums/FormActions';

export const getActionType = (action?: string): FormAction => {
  switch (action) {
    case 'create':
      return FormAction.Create;
    case 'update':
      return FormAction.Update;
    case 'view':
      return FormAction.View;
    default:
      return FormAction.Unknown;
  }
};

export const getFormTitleByAction = ({ action, model }: { action?: FormAction, model: string }): string => {
  switch (action) {
    case FormAction.Update: {
      return `Edit ${model}`;
    }
    case FormAction.Create: {
      return `Create a new ${model}`;
    }
    case FormAction.View:
    default: {
      return model;
    }
  }
};