// eslint-disable-next-line no-shadow
export enum FormSectionItemTypes {
  field,
  button,
  table,
  gantt,
}

// eslint-disable-next-line no-shadow
export enum FormFieldTypes {
  text,
  multichoice,
  checkbox,
  date,
  link,
  duration,
  number,
}