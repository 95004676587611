import React from 'react';
import PrismaTable from '../components/PrismaTable';
import ScopeAdminStyle from '../components/ScopeAdminStyle';

const Vaccination: React.FC = () => {
  return (
    <ScopeAdminStyle>
      <PrismaTable actions={[]} model="Vaccination"/>;
    </ScopeAdminStyle>
  );
};

export default Vaccination;
