import React from 'react';
import DefaultModelPage from '../components/DefaultModelPage';
import TableWrapper from '../components/Table/TableWrapper';
import LanguageItemForm from '../components/forms/LanguageItem';

const modelId = 'LanguageItem';
const modelName = 'Language Item';

const columns = [
  { key: 'id' },
  { key: 'description' },
  { key: 'content', cell: ({value}) => (<div
    style={{width: '400px'}}
    dangerouslySetInnerHTML={{__html: value}}
  />) },
];

const PaymentTerm: React.FC = () => {
  return (
    <DefaultModelPage
      columns={columns}
      modelId={modelId}
      modelName={modelName}
      FormComponent={LanguageItemForm}
      MainComponent={TableWrapper}
    />
  );
}

export default PaymentTerm;
