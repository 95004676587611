export const LIST_ITEMS = [
  {
    path: '/admin/User',
    title: 'Users',
  },
  {
    path: '/admin/VaccinationType',
    title: 'Vaccination types',
  },
  {
    path: '/admin/Vaccination',
    title: 'Vaccinations',
  },
  {
    path: '/admin/MeningitisWaiver',
    title: 'Meningitis waivers',
  },
  {
    path: '/admin/GeneralHealthQuestion',
    title: 'General health questions',
  },
  {
    path: '/admin/GeneralHealthAnswerOption',
    title: 'General health answer options',
  },
  {
    path: '/admin/GeneralHealthAnswer',
    title: 'General health answers',
  },
  {
    path: '/admin/Attachment',
    title: 'Attachment',
  },
  {
    path: '/admin/Language',
    title: 'Language',
  },
];