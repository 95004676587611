import React from 'react';
import { Form, Input } from 'antd';
import IFieldProps from './IFieldProps';

interface ITextField extends IFieldProps {
  hidden?: boolean;
}

const TextField: React.FC<ITextField> = (props) => {
  const { label, name, required, tooltip, hidden = false, style = undefined } = props;
  const rules = [{ required }];
  if (props.validate) {
    rules.push(props.validate);
  }
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      tooltip={tooltip}
      hidden={hidden}
      style={style}
    >
      <Input
        type={hidden ? 'hidden' : 'text'}
        ref={props.inputRef}
        placeholder={props.placeholder}
      />
    </Form.Item>
  );
};

export default TextField;