import React, { useContext, useMemo, useState } from 'react';
import { FormAction } from './Form/enums/FormActions';
import FormModal from './modals/FormModal';
import { useMutation } from '@apollo/client';
import { mutationDocument } from './QueryDocument';
import { Context } from './Context';
import { IFormProps } from './Form/interfaces/IForm';
import { ITableWrapper, TableColumn } from './types';
import { message } from 'antd';
import { getFormTitleByAction } from './Form/utils';

interface IDefaultModelPage {
  FormComponent: React.FC<IFormProps | any>;
  MainComponent: React.FC<ITableWrapper>;
  columns: TableColumn[];
  modelId: string;
  modelName: string;
  onDelete?: (ids: string[]) => void;
}

const DefaultModelPage: React.FC<IDefaultModelPage> = (props) => {
  const { FormComponent, MainComponent, columns, modelId, modelName, onDelete } = props;

  const { schema, eventEmitter } = useContext(Context);

  const [modalState, setModalState] = useState<{ action?: FormAction, record?: any }>({});

  const [deleteMany] = useMutation(
    mutationDocument(schema, modelId, 'delete')
  );

  const modalTitle = useMemo(
    () => getFormTitleByAction({ action: modalState.action, model: modelName }),
    [modalState.action, modelName],
  );

  const onCreateClick = () => {
    setModalState({
      action: FormAction.Create,
    });
  };

  const onUpdateClick = (record: any) => {
    setModalState({
      action: FormAction.Update,
      record,
    });
  };

  const onViewClick = () => {
    // TODO
    message.info('Viewing a record is currently under development.');
  };

  const resetModal = () => {
    setModalState({});
  };

  const handleDelete = async (ids: string[]) => {
    try {
      await onDelete?.(ids);
      await deleteMany({
        variables: {
          where: {
            id: {
              in: ids,
            },
          },
        },
      });
      eventEmitter.emit(`get-data-table-${modelId}`);
      resetModal();
    } catch (e) {
      console.error(e);
      message.error('Error trying delete record');
    }
  };

  return (
    <>
      <MainComponent
        onCreateClick={onCreateClick}
        onUpdateClick={onUpdateClick}
        onViewClick={onViewClick}
        columns={columns}
        modelId={modelId}
        onDelete={handleDelete}
      />
      <FormModal
        title={modalTitle}
        FormComponent={FormComponent}
        action={modalState.action}
        record={modalState.record}
        open={!!Object.keys(modalState).length}
        reset={resetModal}
        onDelete={handleDelete}
      />
    </>
  );
}

export default DefaultModelPage;
