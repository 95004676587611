import React, { useEffect } from 'react';
import { Form, Button, Select, Input, Modal } from 'antd';
import IFieldProps from '../IFieldProps';
import { EditorContent, useEditor, Extension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';

import { 
  BoldOutlined, 
  ItalicOutlined, 
  UnderlineOutlined, 
  StrikethroughOutlined, 
  OrderedListOutlined, 
  UnorderedListOutlined, 
  LinkOutlined, 
  PictureOutlined, 
  AlignLeftOutlined, 
  AlignCenterOutlined, 
  AlignRightOutlined, 
  HddOutlined 
} from '@ant-design/icons';

import './TiptapStyles.css';

interface IRichTextField extends IFieldProps {
  hidden?: boolean;
}

// Custom Font Family Extension
const FontFamily = Extension.create({
  name: 'fontFamily',

  addOptions() {
    return {
      types: ['textStyle'],
      fontFamilies: ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) => element.style.fontFamily.replace(/['"]/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {};
              }
              return {
                style: `font-family: ${attributes.fontFamily}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontFamily:
        (fontFamily) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontFamily }).run();
        },
    };
  },
});

// Toolbar component with Ant Design Buttons and Font Selector
const TiptapToolbar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const addImage = () => {
    Modal.confirm({
      title: 'Insert Image',
      content: (
        <Input
          placeholder="Enter image URL"
          onChange={(e) => editor.chain().focus().setImage({ src: e.target.value }).run()}
        />
      ),
      onOk: () => {},
    });
  };

  const addLink = () => {
    const url = prompt('Enter the URL');
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
    }
  };

  return (
    <div className="tiptap-toolbar">
      <Select
        defaultValue="Arial"
        style={{ width: 150 }}
        onChange={(value) => editor.chain().focus().setFontFamily(value).run()}
        options={[
          { value: 'Arial', label: 'Arial' },
          { value: 'Courier New', label: 'Courier New' },
          { value: 'Georgia', label: 'Georgia' },
          { value: 'Times New Roman', label: 'Times New Roman' },
          { value: 'Verdana', label: 'Verdana' },
        ]}
      />
      <Button
        icon={<BoldOutlined />}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        type={editor.isActive('bold') ? 'primary' : 'default'}
      />
      <Button
        icon={<ItalicOutlined />}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        type={editor.isActive('italic') ? 'primary' : 'default'}
      />
      <Button
        icon={<UnderlineOutlined />}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        type={editor.isActive('underline') ? 'primary' : 'default'}
      />
      <Button
        icon={<StrikethroughOutlined />}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        type={editor.isActive('strike') ? 'primary' : 'default'}
      />
      <Button
        icon={<OrderedListOutlined />}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        type={editor.isActive('orderedList') ? 'primary' : 'default'}
      />
      <Button
        icon={<UnorderedListOutlined />}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        type={editor.isActive('bulletList') ? 'primary' : 'default'}
      />
      <Button
        icon={<LinkOutlined />}
        onClick={addLink}
        type={editor.isActive('link') ? 'primary' : 'default'}
      />
      <Button
        icon={<PictureOutlined />}
        onClick={addImage}
        type="default"
      />
      <Button
        icon={<AlignLeftOutlined />}
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        type={editor.isActive({ textAlign: 'left' }) ? 'primary' : 'default'}
      />
      <Button
        icon={<AlignCenterOutlined />}
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        type={editor.isActive({ textAlign: 'center' }) ? 'primary' : 'default'}
      />
      <Button
        icon={<AlignRightOutlined />}
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        type={editor.isActive({ textAlign: 'right' }) ? 'primary' : 'default'}
      />
      {/* <Button
        icon={<AlignJustifyOutlined />}
        onClick={() => editor.chain().focus().setTextAlign('justify').run()}
        type={editor.isActive({ textAlign: 'justify' }) ? 'primary' : 'default'}
      /> */}
      <Button
        icon={<HddOutlined />}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        type={editor.isActive('heading', { level: 1 }) ? 'primary' : 'default'}
      >
        H1
      </Button>
      <Button
        icon={<HddOutlined />}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        type={editor.isActive('heading', { level: 2 }) ? 'primary' : 'default'}
      >
        H2
      </Button>
      <Button
        icon={<HddOutlined />}
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        type={editor.isActive('heading', { level: 3 }) ? 'primary' : 'default'}
      >
        H3
      </Button>
    </div>
  );
};

const TiptapEditor = ({ value = '', onChange }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      Italic,
      Underline,
      Strike,
      Heading.configure({ levels: [1, 2, 3] }),
      BulletList,
      OrderedList,
      ListItem,
      Link.configure({ openOnClick: false }),
      Image,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      FontFamily, // Adding the custom font family extension
    ],
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none',
      },
    },
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <div className='rich-text-field-wrapper'>
      <TiptapToolbar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

const RichTextField: React.FC<IRichTextField> = (props) => {
  const { label, name, required, tooltip, hidden = false, style = undefined } = props;
  const rules = [{ required }];

  const [form] = Form.useForm();

  if (props.validate) {
    rules.push(props.validate);
  }
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      tooltip={tooltip}
      hidden={hidden}
      style={style}
    >
        <TiptapEditor
          value={form.getFieldValue(name)}
          onChange={(value) => form.setFieldsValue({ [name as string]: value })}
        />
    </Form.Item>
  );
};

export default RichTextField;