import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Popconfirm, Space, Table as AntTable } from 'antd';
import { CheckOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { getColumn, getColumns } from './Columns';
import { Context } from '../Context';
import { FormAction } from '../Form/enums/FormActions';
import { lowercaseFirstLetter, millisecondsToDays, uppercaseFirstLetter } from '../../../utils';
import pluralize from 'pluralize';
import { AdminSchemaColumn, AdminSchemaModel, TableColumn } from '../types';
import dayjs from 'dayjs';
import { dateFormat } from '../../../constants';
import { FormFieldTypes } from '../Form/enums/FormTypes';
import { Key } from 'antd/es/table/interface';

interface ITable {
  data?: any[];
  modelId: string;
  isEmbedded?: boolean;
  loading?: boolean;
  showPagination?: boolean;
  secondaryHeader?: string;
  emptyText?: string;
  columns?: TableColumn[];
  customColumns?: ColumnsType<any>;
  onRemoveClick?: (keys: React.Key[]) => void;
  headerActions?: ReactNode;
  rowSelection?: {
    selectedRowKeys: Key[];
    onChange?: (selectedRowKeys: Key[], selectedRows: any[]) => void;
  }
}

interface ITableCellProps {
  column: AdminSchemaColumn,
  isNested: boolean,
  value: any,
  record,
  modelFields,
  pagesPath 
}

const Cell: React.FC<ITableCellProps> = ({ column, isNested, value, record, modelFields, pagesPath }) => {
  if (!value) return;

  const getFlatValue = () => {
    const columnKeyParts = column.key.split('.');
    const res = columnKeyParts.slice(1).reduce((acc, key) => acc?.[key], value);
    return column.displayField ? res?.[column.displayField] : res;
  };

  const flatValue = isNested
    ? getFlatValue()
    : column.displayField ? value[column.displayField] : value;

  if (typeof flatValue === 'boolean') {
    return flatValue ? <CheckOutlined /> : '';
  }

  switch (column.fieldType) {
    case FormFieldTypes.duration:
      return flatValue ? `${millisecondsToDays(flatValue)} days` : '';
    case FormFieldTypes.date:
      return flatValue ? dayjs(flatValue).format(dateFormat) : '';
    case FormFieldTypes.checkbox:
      return flatValue ? <CheckOutlined /> : '';
  }

  switch (column.key) {
    case 'password':
      return '•'.repeat(flatValue.length);
    case 'token':
      return;
    default:
      if (column.key.startsWith('related')) {
        return renderRelatedColumn(column, flatValue, record, modelFields, pagesPath);
      }
      return flatValue;
  }
};

const getPaginationConfig = (showPagination, pageSize, totalCount, modelName) => {
  if (!showPagination) return false;
  return {
    showTotal: (total) => `Total ${total} ${pluralize(modelName + '', total)}`,
    pageSize,
    total: totalCount,
  };
};

const Table: React.FC<ITable> = ({
  data: value = [],
  isEmbedded = false,
  loading = false,
  showPagination = true,
  secondaryHeader,
  emptyText,
  columns,
  customColumns,
  modelId,
  headerActions,
  onRemoveClick,
  rowSelection: rowSelectionProp,
}) => {
  const context = useContext(Context);
  const { schema, pagesPath, pageSize: defaultPageSize } = context;
  const { models } = schema;

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const modelsMapped = useMemo(() => {
    const _modelsMapped: { [modelId: string]: AdminSchemaModel } = {};
    models.forEach((model) => {
      _modelsMapped[model.id] = model;
    });
    return _modelsMapped;
  }, [models]);

  const { modelName, modelFields } = useMemo(() => {
    const _modelObject = models.find((item) => item.id === modelId);
    return {
      modelName: _modelObject?.name,
      modelFields: _modelObject?.fields,
    };
  }, [modelId, models]);

  const columnList = useColumnList(columns, modelFields, modelsMapped);

  const handleDeleteSelected = useCallback(async () => {
    await onRemoveClick?.(selectedRowKeys);
    setSelectedRowKeys([]);
  }, [onRemoveClick, selectedRowKeys]);

  const onSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const _value = e.target.value?.toLowerCase();
    setFilterQuery(_value);
    if (!_value) {
      setFilteredData(value);
      return;
    }
    const filterableFields = modelFields?.map(({ name }) => name) || [];
    const newFilteredData = value.filter((entry: any) => 
      filterableFields.some((field) => 
        entry[field]?.toString().toLowerCase().includes(_value)
      )
    );
    setFilteredData(newFilteredData);
  }, [value, modelFields]);

  const totalItemCount: number = value?.length ?? 0;
  const totalItemCountFiltered: number = filteredData?.length ?? 0;
  const hasSelected = selectedRowKeys.length > 0;

  const antTableColumns = useAntTableColumns(columnList, customColumns, modelFields, pagesPath);

  const rowSelection = useRowSelection(onRemoveClick, rowSelectionProp, selectedRowKeys, setSelectedRowKeys);

  return (
    <>
      <TableHeader
        secondaryHeader={secondaryHeader}
        hasSelected={hasSelected}
        headerActions={headerActions}
        selectedRowKeys={selectedRowKeys}
        handleDeleteSelected={handleDeleteSelected}
        filterQuery={filterQuery}
        onSearch={onSearch}
      />
      <AntTable
        columns={antTableColumns}
        dataSource={filterQuery ? filteredData : value}
        rowSelection={rowSelection}
        bordered
        scroll={{ x: 'max-content' }}
        loading={loading}
        onChange={(pagination) => {
          setPageSize(pagination.pageSize || defaultPageSize);
        }}
        pagination={getPaginationConfig(showPagination, pageSize, filterQuery ? totalItemCountFiltered : totalItemCount, modelName)}
        size={isEmbedded ? 'small' : 'middle'}
        style={showPagination ? undefined : { marginBottom: '20px' }}
        locale={emptyText ? { emptyText } : undefined}
      />
    </>
  );
};

// Extracted helper functions

const useColumnList = (columns: TableColumn[] | undefined, modelFields, modelsMapped): AdminSchemaColumn[] => {
  return useMemo(() => {
    const allPossibleColumns = getColumns(modelFields);
    const allPossibleColumnsMapped = allPossibleColumns.reduce((acc, column) => {
      acc[column.key] = column;
      return acc;
    }, {});

    const getColumnFromKey = (column: TableColumn) => {
      const columnKeyParts = column.key.split('.');
      if (columnKeyParts.length < 2) return;
      const fieldName = columnKeyParts.pop();
      const modelId = uppercaseFirstLetter(columnKeyParts.pop());
      const field = modelsMapped[modelId]?.fields?.find(({ name }) => fieldName === name);
      return field ? { ...getColumn(field), key: column.key } : undefined;
    };

    if (!columns) {
      return allPossibleColumns;
    }

    return columns
        .map((_column: TableColumn) => {
          const column = allPossibleColumnsMapped[_column.key] || getColumnFromKey(_column);
          return column ? {
            ...column,
            displayField: _column.displayField,
            title: _column.title || column.title,
            cell: _column.cell,
          } : null;
        })
        .filter(Boolean)
  }, [columns, modelFields, modelsMapped]);
};



const renderRelatedColumn = (column, flatValue, _record, modelFields, pagesPath) => {
  const keyToLookFor = lowercaseFirstLetter(column.key.replace('related', ''));
  const field = modelFields?.find(({ name }) => name === keyToLookFor);
  const relatedModelId = field?.type;
  if (relatedModelId) {
    const href = `${pagesPath}${relatedModelId}/${flatValue}?action=${FormAction.View}`;
    return (
      <Link to={href}>
        {_record[keyToLookFor]?.[column.displayField] || flatValue}
      </Link>
    );
  }
};

const useAntTableColumns = (columnList: AdminSchemaColumn[], customColumns, modelFields, pagesPath): ColumnsType<any> => {
  return useMemo(() => {
    const dynamicColumns = columnList?.map(column => {
      const isNested = column.key.includes('.');
      const dataIndex = isNested ? column.key.split('.')[0] : column.key;
      const ColumnCell = column.cell || Cell;
      return {
        ...column,
        dataIndex,
        render: (value, record) => (
          <ColumnCell column={column} 
            isNested={isNested}
            value={value}
            record={record}
            modelFields={modelFields}
            pagesPath={pagesPath}
          />
        )
      };
    }) || [];

    return [...(customColumns || []), ...dynamicColumns];
  }, [columnList, customColumns, modelFields, pagesPath]);
};

const useRowSelection = (onRemoveClick, rowSelectionProp, selectedRowKeys, setSelectedRowKeys) => {
  return useMemo(() => {
    if (!onRemoveClick) {
      return rowSelectionProp;
    }
    return {
      selectedRowKeys,
      onChange: setSelectedRowKeys,
      fixed: true,
    }
  }, [onRemoveClick, rowSelectionProp, selectedRowKeys, setSelectedRowKeys]);
};

const TableHeader: React.FC<{
  secondaryHeader?: string;
  hasSelected: boolean;
  headerActions?: ReactNode;
  selectedRowKeys: React.Key[];
  handleDeleteSelected: () => void;
  filterQuery: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  secondaryHeader,
  hasSelected,
  headerActions,
  selectedRowKeys,
  handleDeleteSelected,
  filterQuery,
  onSearch,
}) => (
  <Space
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 20px 20px 0',
      minHeight: '35px',
    }}>
    <Space wrap>
      {secondaryHeader && !hasSelected && (
        <div style={{ marginTop: 0, marginLeft: '10px' }}>
          {secondaryHeader}
        </div>
      )}
      {headerActions}
      {hasSelected && (
        <>
          <Popconfirm
            title={`Are you sure to delete ${selectedRowKeys.length > 1 ? 'these records' : 'this record'}?`}
            onConfirm={handleDeleteSelected}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button danger icon={<DeleteOutlined />}>
              Delete Selected
            </Button>
          </Popconfirm>
          <div>
            Selected <strong>{selectedRowKeys.length}</strong> record{selectedRowKeys.length > 1 ? 's' : ''}
          </div>
        </>
      )}
    </Space>
    <Space direction="vertical">
      <Input
        placeholder="Search records..."
        value={filterQuery}
        onChange={onSearch}
        prefix={<SearchOutlined />}
        style={{ margin: '0 20px' }}
      />
    </Space>
  </Space>
);

export default Table;