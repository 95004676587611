import { AdminSchemaField, AdminSchemaColumn } from '../types';
import { KindEnum } from '../../../schema/enums/Kind';
import { FormFieldTypes } from '../Form/enums/FormTypes';

const getFieldType = (field: AdminSchemaField): FormFieldTypes => {
  if (field.list) {
    return FormFieldTypes.multichoice;
  }
  switch (field.type) {
    case 'Int':
    case 'Float':
      return FormFieldTypes.number;
    case 'Boolean':
      return FormFieldTypes.checkbox;
    case 'DateTime':
      return FormFieldTypes.date;
    case 'Duration':
      return FormFieldTypes.duration;
    case 'Json': // idk
    case 'String':
    default:
      return FormFieldTypes.text;
  }
};

export const getColumn = (field: AdminSchemaField): AdminSchemaColumn => {
  // possible to add "displayField" to adminSettings, and read it here
  const fieldType = getFieldType(field);
  const foreignModel = field.kind === KindEnum.OBJECT ? field.type : undefined;
  return {
    title: field.title,
    key: field.name,
    fieldType,
    foreignModel,
    disableFilters: !field.filter || field.list,
  };
};

export const getColumns = (fields?: AdminSchemaField[]) => {
  return fields
    ? fields
      .slice()
      .sort((a, b) => a.order - b.order)
      .filter((field) => field.read)
      .map(getColumn)
    : [];
};