import { AdminSchema, AdminSchemaModel } from './types';
import tag from 'graphql-tag';
import { KindEnum } from '../../schema/enums/Kind';
import { lowercaseFirstLetter } from '../../utils';
import pluralize from 'pluralize';

const getFields = (schema: AdminSchema, modelName: string, update = false, requiredFields = []) => {
  const { models } = schema;
  const model = models.find((item) => item.id === modelName);
  if (!model) {
    return 'id';
  }

  let fieldsString = `${model.idField} `;
  model?.fields.forEach((field) => {
    // eslint-disable-next-line no-mixed-operators
    if (field.read && field.name !== model.idField || update && field.update) {
      if (field.kind !== KindEnum.OBJECT) {
        fieldsString += `${field.name} `;
      } else if (!field.list || update) {
        const fieldModel = models.find((item) => item.id === field.type);
        if (fieldModel) {
          fieldsString += `${field.name} {`;
          if (fieldModel.idField) {
            fieldsString += `${fieldModel.idField} `;
          } else {
            fieldModel.fields
              .filter((item) => item.kind === KindEnum.SCALAR)
              // eslint-disable-next-line no-shadow
              .forEach((field) => {
                fieldsString += `${field.name} `;
              });
          }

          const fields = fieldModel.displayFields;
          fields.forEach((item) => {
            const splitItems = item.split('.');
            for (let i = 0; i < splitItems.length; i++) {
              if (i + 1 < splitItems.length) {
                fieldsString += `${splitItems[i]} { `;
              } else if (!(splitItems.length === 1 && splitItems[i] === fieldModel.idField)) {
                fieldsString += `${splitItems[i]} `;
              }
            }
            for (let i = 1; i < splitItems.length; i++) {
              fieldsString += '} ';
            }
          });
          fieldsString += '} ';
        }
      }
    }
  });

  return fieldsString;
};

const allScalar = (model?: AdminSchemaModel) => {
  return model?.fields
    .filter((item) => item.kind === KindEnum.SCALAR)
    .map((item) => item.name)
    .join(' ');
};
export const queryDocument = (schema: AdminSchema, modelName: string, findUnique = false, update = false) => {
  const fields = getFields(schema, modelName, update);

  const queryName = lowercaseFirstLetter(modelName);
  if (findUnique) {
    return tag`
query ${queryName}($where: ${modelName}WhereUniqueInput!) {
  ${queryName} : findMany${modelName}(where: $where) {
    ${fields}
  }
}
`;
  } else {
    const pluralizedQueryName = pluralize(queryName, 2, false);
    return tag`
query ${pluralizedQueryName}(
  $where: ${modelName}WhereInput
  $orderBy: [${modelName}OrderByWithRelationInput!]
  $cursor: ${modelName}WhereUniqueInput
  $skip: Int
  $take: Int
) {
  ${pluralizedQueryName} : findMany${modelName}(
     where: $where,
     orderBy: $orderBy,
     cursor: $cursor,
     skip: $skip,
     take: $take,
    ) {
    ${fields}
  }
}
`;
  }
};

export const mutationDocument = (
  schema: AdminSchema,
  model: string,
  mutation: 'create' | 'update' | 'delete',
) => {
  const fields = getFields(schema, model, true);
  const modelObject = schema.models.find((item) => item.id === model);
  switch (mutation) {
    case 'create':
      return tag`mutation createOne${model}($data: ${model}CreateInput!) {
  createOne${model}(data: $data) {
    ${modelObject?.idField || allScalar(modelObject)}
  }
}`;
    case 'delete':
      return tag`mutation deleteMany${model} ($where: ${model}WhereInput!) {
  deleteMany${model} (where: $where) {
    count
  }
}`;
    case 'update':
      return tag`mutation updateOne${model} ($where: ${model}WhereUniqueInput!, $data: ${model}UpdateInput!) {
  updateOne${model} (where: $where, data: $data) {
    ${fields}
  }
}`;
  }
};
